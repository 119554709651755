<template>
  <div id="comment" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <div class="columns">
          <div class="column is-6 is-offset-3">
            <form @submit.prevent="passes(submit)">
              <SdInput
                type="text"
                rules="required|min:5|max:100"
                label="Description"
                v-model="comment.description"
                placeholder="Enter a description"
              />
              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '../../components/SdInput'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'
import { fetchUser } from '@/assets/js/app_info.js'

export default {
  comment: 'comment',
  data() {
    return {
      schoolId: null,
      userId: null,
      pageTitle: 'Comment',
      skipQuery: true,
      comment: {
        id: null,
        schoolId: null,
        userId: null,
        description: '',
      },
    }
  },
  apollo: {
    comment: {
      query: gql`
        query comment($id: ID!) {
          comment(id: $id) {
            id
            schoolId
            userId
            description
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
  },
  methods: {
    submit() {
      const description = this.comment.description
      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createComment(
                $schoolId: Int!
                $userId: Int!
                $description: String!
              ) {
                createComment(
                  input: {
                    schoolId: $schoolId
                    userId: $userId
                    description: $description
                  }
                ) {
                  comment {
                    id
                    description
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              schoolId: this.schoolId,
              userId: this.userId,
              description: description,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createComment.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/comments`
              )
            })
          })
          .catch((error) => {
            // console.error(error);
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateComment($id: Int!, $description: String!) {
                updateComment(input: { id: $id, description: $description }) {
                  comment {
                    id
                    description
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.comment.id),
              description: description,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateComment.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/comments`
              )
            })
          })
          .catch((error) => {
            // console.error(error);
          })
      }
    },
  },
  components: {
    SdInput,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    this.$store.commit('setPageTitle', this.pageTitle)
    fetchUser().then((user) => {
      this.userId = user.id
      this.schoolId = user.school_id
      this.$store.commit('setSubMenus', [
        {
          name: 'Comments',
          route: `/school/${this.schoolId}/comments`,
        },
      ])
    })
  },
}
</script>

<style lang="scss" scoped></style>
style
